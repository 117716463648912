<template>
  <div>
    <b-button v-if="fotoSrc != null" @click="removerArquivo" class="btn-remove p-0" variant="danger" pill>
      <div class="d-flex">
        <feather type="x" size="14"/>
      </div>
    </b-button>
    <div v-on:click="fileUp" @mouseover="showOverlay = true" @mouseleave="showOverlay = false" class="upload-box-pic arquivo" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <div v-if="fotoSrc && showOverlay" class="overlay">
        <img :src="upIcon" />
      </div>
      <img class="img-upload" v-if="fotoSrc" :src="fotoSrc" />
      <img :src="upIcon" />
      <input accept=".jpg, .jpeg, .png, .svg" type="file" id="fileup-input" @change="inputUpload" class="fileup-input" name="" />
    </div>    
  </div>
</template>

<script>
export default {
  name: "UploadFoto",
  props: {
    type: { type: String },
    tituloArquivo: { type: String },
    selecioneArquivo: { type: String },
    fotoSrc: { type: String },
  },
  data() {
    return {
      upIcon: require("./upload.svg"),
      showOverlay: false,
      file: this.fotoSrc,
    };
  },
  methods: {
    removerArquivo() {
      this.fotoSrc = null;
      this.$emit("upload", null);
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    inputUpload(event) {
      this.drop(event);
      event.target.value = "";
    },
    fileUp() {
      document.getElementById("fileup-input").click();
    },
    drop(event) {
      event.preventDefault();

      let files = event.target.files || event.dataTransfer.files;
      this.getBase64(files[0]).then((data) => {
        this.fotoSrc = data;
        this.$emit("upload", data);
      });
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      event.preventDefault();
    },
  },
};
</script>
<style>
  .btn-remove{
    position: absolute;
    margin-left: 134px;
    margin-top: 8px;
    cursor: pointer;
  }
</style>