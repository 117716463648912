import ApiService from "@/common/api/api.service";
import helpers from "@/common/utils/helpers";
import Store from "../../../store/Store";

const UsuarioService = {
  listar : function(filtro, paginacao = 0, porPagina = 0, ativo){
    let link = `usuario?NumeroDaPagina=${paginacao}&TamanhoDaPagina=${porPagina}`;
    let estabecimentoSelecionado = Store.getters['estabelecimentoSelecionado'];
    link = link.concat(`${estabecimentoSelecionado ? `&estabelecimentoId=${estabecimentoSelecionado}` : ''}`);
    link = link.concat(`${filtro ? `&TermoPesquisa=${filtro}` : ''}`);
    link = link.concat(`${ativo ? `&Ativo=true` : ''}`);
    return ApiService.get(link);
  },
  salvar : function(form){
    if(form.perfil == 'Valorem' || form.perfil == 'Gerente')
      form.supervisorId = null;
      
    if(form.id){
      return ApiService.put("usuario", form);
    }
    return ApiService.post("usuario", form);
  },
  ativarInativar : function(form, ativarInativar){
    if(ativarInativar){
      return ApiService.put(`usuario/ativar`, form);
    }
    return ApiService.put(`usuario/inativar`, form);
  },
  buscar : function(id){
    return ApiService.get(`usuario/${id}`);
  },
  verificarForcaDaSenha(form) {
    return ApiService.post(`trocasenhaforte/checar-senha?request=${form}`, "", {
      noToast: true,
    });
  },
  alterarSenha(form) {
    return ApiService.put(`usuario/alterar-senha`, form);
  },
  buscarSupervisores(){
    return ApiService.get(`usuario/dropdown`);
  },
  buscarUsuarios(){
    return ApiService.get(`usuario/dropdown`);
  },
  buscarUsuariosPorPerfil(form){
    return ApiService.post(`usuario/dropdown-por-perfil`, form);
  },
  esqueceuSenha(form) {
    form.cpf = form.cpf ? helpers.removerMascara(form.cpf) : '';
    return ApiService.post(`usuario/solicitar-reset-senha?email=${form.email}&cpf=${form.cpf}`, { ...form });
  },
  resetarSenha(form) {
    return ApiService.put(`usuario/reset-senha`, { ...form });
  },
  obterToken(id) {
    return ApiService.get(`usuario/obter-reset-token/?id=${id}`);
  },
};

export default UsuarioService;
