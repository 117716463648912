<template lang="">
  <div>
    <b-card style="margin-top: 90px">
      <div class="formulario-usuario-foto">
        <upload-foto
          @upload="(img) => (form.foto = img)"
          :fotoSrc="form.foto"
        />
      </div>
      <div class="d-flex justify-content-end">
        <input-checkbox
          id="input-cancelamento-automatico"
          switch
          :text="$t('USUARIOS.CANCELAMENTO_AUTOMATICO')"
          v-model="form.cancelamentoAutomatico"
          v-if="!telaMinhaConta"
        />
      </div>
      <div class="row" style="margin-top: 60px">
        <div class="col-12">
          <h4 class="card-title mb-3">
            {{ $t(`USUARIOS.TITULO_DADOS`) }}
          </h4>
        </div>
      </div>
      <div class="row">
        <input-text
          id="input-nome"
          class="col-12 col-md-3"
          :label="$t('USUARIOS.TABELA.NOME')"
          v-model="form.nome"
          ref="form-nome"
          required
        />
        <input-text
          id="input-email"
          class="col-12 col-md-2"
          :label="$t('USUARIOS.TABELA.EMAIL')"
          v-model="form.email"
          ref="form-email"
          type="email"
          required
        />
        <input-mask
          id="input-cpf"
          class="col-12 col-md-2"
          :label="$t('USUARIOS.TABELA.CPF')"
          :mask="['###.###.###-##']"
          type="cpf"
          v-model="form.cpf"
          ref="form-cpf"
          required
        />
        <input-mask
          id="input-contato"
          class="col-12 col-md-2"
          :label="$t('USUARIOS.TABELA.CONTATO')"
          :mask="['(##)####-####', '(##)#####-####']"
          v-model="form.numeroTelefone"
          ref="form-numeroTelefone"
          required
        />
        <input-select-tag
          id="input-estabelecimento"
          class="col-12 col-md-3"
          sem-options-labels
          :options="opcoes.estabelecimentos"
          v-model="form.estabelecimentoIds"
          :label="$t('USUARIOS.TABELA.EMPRESAS')"
        />
      </div>
      <div class="mt-2 row" v-if="!telaMinhaConta">
        <input-radio
          id="input-perfil"
          class="col-12 col-md-6"
          :label="$t(`USUARIOS.TITULO_PERFIL`)"
          :options="perfisFiltrado"
          v-model="form.perfil"
          ref="form-perfil"
          required
        />
        <input-select-tag
          id="input-supervisor"
          v-if="temSupervisor"
          class="col-12 col-md-6"
          unico
          sem-options-labels
          :options="opcoes.supervisores"
          v-model="form.supervisorId"
          :label="$t('USUARIOS.TABELA.SUPERVISOR')"
        >
          <template #label>
            <div class="d-inline-flex align-items-center">
              <span class="mr-2">
                {{ $t("USUARIOS.TABELA.SUPERVISOR") }}
              </span>
              <feather
                type="help-circle"
                size="14"
                v-b-tooltip.hover
                :title="$t('USUARIOS.TOOLTIP.SUPERVISOR')"
              ></feather>
            </div>
          </template>
          <template #option="dados">
            <div class="d-flex align-items-center justify-content-between">
              <div>{{ dados.text }}</div>
              <b-badge :variant="retornarPerfil(dados.perfil).variante">{{
                retornarPerfil(dados.perfil).text
              }}</b-badge>
            </div>
          </template>
          <template #singleLabel="dados">
            <div class="d-flex align-items-center justify-content-between">
              <div>{{ dados.text }}</div>
              <b-badge :variant="retornarPerfil(dados.perfil).variante">{{
                retornarPerfil(dados.perfil).text
              }}</b-badge>
            </div>
          </template>
        </input-select-tag>
      </div>
      <div class="mt-2">
        <h4 class="card-title mb-3">
          {{ $t(`USUARIOS.TITULO_ACESSO`) }}
        </h4>
      </div>
      <div class="row">
        <input-text
          id="input-usuario"
          class="col-12 col-md-4"
          :label="$t('USUARIOS.TABELA.USUARIO')"
          v-model="form.userName"
          ref="senhaForm-usuario"
          required
        />
        <div class="col-12 col-md-4">
          <input-text
            id="input-senha"
            type="password"
            :label="$t('USUARIOS.TABELA.SENHA')"
            v-model="form.senha"
            @input="verificarForcaDaSenha"
            ref="senhaForm-senha"
            required
          />
          <verifica-forca-senha
            embaixo
            :value="forcaSenha"
          ></verifica-forca-senha>
        </div>
        <input-text
          id="input-confirmar-senha"
          type="password"
          class="col-12 col-md-4"
          :label="$t('USUARIOS.TABELA.CONFIRMACAO_SENHA')"
          v-model="form.confirmacaoSenha"
          ref="senhaForm-confirma"
          required
        >
          <template #append>
            <b-button
              @click="alterarSenha"
              v-if="(id || telaMinhaConta) && permissao"
              class="ml-2"
              variant="success"
            >
              {{ $t("USUARIOS.ALTERAR_SENHA") }}
            </b-button>
          </template>
        </input-text>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <b-button
          id="btn-cancelar"
          @click="cancelar"
          variant="outline-success"
          v-if="!telaMinhaConta"
          class="mr-2"
        >
          Cancelar
        </b-button>
        <b-button
          id="btn-salvar"
          @click="salvar"
          variant="success"
          v-if="permissao"
        >
          Salvar
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import { SETAR_FOTO } from "@/store/autenticacao.module";
import {
  InputText,
  InputMask,
  InputSelectTag,
  InputRadio,
  InputCheckbox,
} from "@/components/inputs";
import UsuarioService from "@/common/services/usuario/usuario.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import helpers from "@/common/utils/helpers";
import cloneDeep from "lodash.clonedeep";
import VerificaForcaSenha from "@/components/outros/VerificaForcaSenha.vue";
import MinhaContaService from "../../common/services/usuario/minha-conta.service";
import uploadFoto from "@/components/uploadFoto";
export default {
  props: ["id"],
  components: {
    InputText,
    InputMask,
    InputSelectTag,
    InputRadio,
    VerificaForcaSenha,
    uploadFoto,
    InputCheckbox,
  },
  data: function () {
    return {
      form: {
        nome: "",
        foto: null,
        cpf: "",
        numeroTelefone: "",
        email: "",
        estabelecimentoIds: [],
        senha: "",
        userName: "",
        confirmacaoSenha: "",
        perfil: "operador",
        supervisorId: "",
        cancelamentoAutomatico: false,
      },
      forcaSenha: "",
      opcoes: {
        estabelecimentos: [],
        perfis: helpers.Perfil,
        supervisores: [],
      },
    };
  },
  methods: {
    salvar: function () {
      this.confirmar(
        this.$t(
          `USUARIOS.TITULO_SALVAR_${this.telaMinhaConta ? "PERFIL" : "USUARIO"}`
        ),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (
          !this.validarFormulario(this.id || this.telaMinhaConta ? "form-" : "")
        )
          return false;

        if (!this.id) {
          if (this.form.senha != this.form.confirmacaoSenha) {
            this.alertaErro(this.$t("USUARIOS.MENSAGEM_SENHA"));
            return;
          }
        }

        this.$store.dispatch(START_LOADING);
        let form = cloneDeep(this.form);
        if (form.estabelecimentoIds == null) form.estabelecimentoIds = [];
        form.cpf = helpers.removerMascara(form.cpf);
        form.numeroTelefone = helpers.removerMascara(form.numeroTelefone);
        (this.telaMinhaConta ? MinhaContaService : UsuarioService)
          .salvar(form)
          .then(() => {
            this.alertaSucesso(this.$t("USUARIOS.MENSAGEM_SUCESSO")).then(
              () => {
                if (!this.telaMinhaConta) {
                  this.$router.push({ name: "usuarios" });
                } else {
                  this.$store.dispatch(SETAR_FOTO, form.foto);
                }
              }
            );
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    alterarSenha: function () {
      this.confirmar(
        this.$t("USUARIOS.TITULO_SENHA"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario("senhaForm")) return false;

        if (this.form.senha != this.form.confirmacaoSenha) {
          this.alertaErro(this.$("USUARIOS.MENSAGEM_SENHA"));
          return;
        }

        this.$store.dispatch(START_LOADING);
        let form = {
          usuarioId: this.id,
          novaSenha: this.form.senha,
          novaConfirmacaoSenha: this.form.confirmacaoSenha,
        };
        (this.telaMinhaConta ? MinhaContaService : UsuarioService)
          .alterarSenha(form)
          .then(() => {
            this.alertaSucesso(this.$t("USUARIOS.MENSAGEM_SUCESSO_SENHA")).then(
              () => {}
            );
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    cancelar: function () {
      this.$router.push({ name: "usuarios" });
    },
    buscar: function () {
      this.$store.dispatch(START_LOADING);
      (this.telaMinhaConta ? MinhaContaService : UsuarioService)
        .buscar(this.id)
        .then((res) => {
          this.form = res.data.data;
          this.form.estabelecimentoIds = res.data.data.estabelecimentos.map(
            (el) => el.id
          );
          this.form.senha = "";
          this.form.confirmacaoSenha = "";
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    verificarForcaDaSenha() {
      let senha = this.form.senha;
      UsuarioService.verificarForcaDaSenha(senha).then((result) => {
        this.forcaSenha = result.data.forcaSenhaEnum;
      });
    },
    buscarEstabelecimentos: function () {
      this.$store.dispatch(START_LOADING);
      MinhaContaService.buscarEstabelecimento()
        .then((res) => {
          this.opcoes.estabelecimentos = res.data.data.estabelecimentos.map(
            (el) => {
              return {
                value: el.id,
                text: el.nome,
              };
            }
          );
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarSupervisores: function () {
      this.$store.dispatch(START_LOADING);
      UsuarioService.buscarSupervisores()
        .then((res) => {
          let supervisores = res.data.data.usuarios.map((el) => {
            return {
              value: el.id,
              text: el.nome,
              perfil: el.perfil,
            };
          });
          if (this.id) {
            this.opcoes.supervisores = supervisores.filter(
              (el) => el.value != this.id
            );
          } else {
            this.opcoes.supervisores = supervisores;
          }
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    retornarPerfil: function (perfil) {
      return helpers.getEnum(perfil);
    },
  },
  mounted() {
    if (this.telaMinhaConta) {
      this.$store.dispatch(DEFINIR_BREADCRUMB, [
        { titulo: this.$t("USUARIOS.TITULO_MINHA_CONTA") },
      ]);
    } else {
      this.$store.dispatch(DEFINIR_BREADCRUMB, [
        { titulo: this.$t("USUARIOS.TITULO_LISTA"), to: "/usuarios" },
        {
          titulo: this.$t(
            `USUARIOS.TITULO_FORMULARIO_${!this.id ? "NOVO" : "EDICAO"}`
          ),
        },
      ]);
    }
    this.buscarEstabelecimentos();
    this.buscarSupervisores();

    if (this.id) {
      this.buscar();
    } else {
      if (this.$store.getters["estabelecimentoSelecionado"])
        this.form.estabelecimentoIds.push(
          this.$store.getters["estabelecimentoSelecionado"]
        );
      this.form.supervisorId = this.$store.getters["usuarioAutenticado"];
    }

    if (this.telaMinhaConta) this.buscar();
  },
  computed: {
    temSupervisor: function () {
      return (
        this.form.perfil != "Valorem" &&
        this.form.perfil != "Gerente" &&
        this.form.perfil != "Supervisor" &&
        this.form.perfil != "MovingPay" &&
        this.form.perfil != "QProfWebhook"
      );
    },
    telaMinhaConta: function () {
      return this.$route.name == "minha-conta";
    },
    perfisFiltrado: function () {
      return this.opcoes.perfis.filter((el) =>
        el.claims.includes(this.$store.state.autenticacao.usuario.perfil)
      );
    },
    usuario: function () {
      return this.$store.state.autenticacao.usuario;
    },
    permissao: function () {
      if (this.$route.name == "minha-conta") return true;
      return helpers.validarAcesso("Usuario")[this.id ? "editar" : "criar"];
    },
  },
};
</script>
<style lang="scss">
.formulario-usuario-foto {
  position: absolute;
  margin-top: -100px;
  border-radius: 50%;
  background: #fff;

  .btn-remove {
    position: absolute;
    margin-left: 153px;
    margin-top: -1px;
    cursor: pointer;
  }
}
</style>
